import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import client, { isClientError } from './api/client'

import Banner from './components/Banner'
import FloatingCart from './components/CartCheckout/FloatingCart'
import { cookieOption, cookiesKey } from './components/CartCheckout/utils'
import Chat from './components/Chat'
import Popup from './components/Popup/Popup'
import { CompanyProvider } from './context/CompanyContext'
import { LocaleProvider } from './context/LocaleContext'
import { FluidChatWrapperStyled, StyledPopup } from './styled'
import type { Company } from './types/Company'
import { getCartItemsLength, sendChatRequest } from './utils'
import useCookieWatcher from './hooks/useCookieWatcher'

const CartCheckout = lazy(
  () => import('./components/CartCheckout/CartCheckout'),
)

declare global {
  interface Window {
    fcs: any
    fluidChatSettings: any
    fluidSettings: any
    Spreedly: any
    showCartCount: Function
  }
  interface WindowEventMap {
    FluidChatPopupButtonClick: CustomEvent
    FluidEnrollmentButtonClick: CustomEvent
  }
}

interface IData {
  affiliate?: object
  visitor?: string
  contact: {
    full_name: string
    email: string
    phone: string
  }
  message: string
  popup_id?: number
}

const appSetting =
  window.fcs || window.fluidChatSettings || window.fluidSettings

const bannerHeight = 40
const bannerStyle = document.createElement('style')

function AppElement(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies()
  const [bannerTop, setBannerTop] = useState(bannerHeight)

  const cookieCartItemsLength = useCookieWatcher('cartItemsLength')

  const [popupEvent, setPopupEvent] = useState(false)

  const [enrollmentEvent, setEnrollmentEvent] = useState(false)

  const [originalChat, setOriginalChat] = useState<{
    modified: boolean
    modifiedBy?: number
    chat: Company['chat']
  }>({
    modified: false,
    chat: {} as Company['chat'],
  })

  const [company, setCompany] = useState<Company>({
    isLoaded: false,
    showCartCheckoutPopup: false,
    popupsChecked: false,
    color: '#2264EA',
    chatOpen: false,
    showBanner: false,
    prompt: true,
    apiUrlHost: '',
    cart: {
      enabled: true,
    },
    autoResponse: {
      message_title: 'We received your message.',
    },
    message: {
      name: '',
      phone: '',
      email: '',
      messageBody: '',
    },
    popups: [],
    formSubmitted: false,
    messageDelivered: false,
    errorSendingMessage: false,
    settings: {},
    defaultCountry: {},
  })

  const [isVisibleChatBubble, setVisibleChatBubble] = useState(false)
  const [popups, setPopups] = useState(false)

  window.showCartCount = function (count: number) {
    setCookie(cookiesKey.cartItemsLength, count, { path: '/' })
    setCompany((prevCompany) => {
      let cart = prevCompany.cart ?? {}
      return { ...prevCompany, cart: { ...cart, enabled: true } }
    })
  }

  useEffect(() => {
    const fonts = ['Eina03-Bold', 'Eina03-SemiBold', 'Eina03-Regular']
    fonts.forEach((x) => {
      let myFont = new FontFace(
        x,
        'url(https://ik.imagekit.io/fluid/s3/fonts/' + x + '.otf)',
      )
      myFont.load()
      document.fonts.add(myFont)
    })
  }, [])

  const updateCartItemsCount = (count: number) =>
    setCompany((prevCompany) => ({
      ...prevCompany,
      cart: { ...prevCompany.cart, items_count: count },
    }))

  useEffect(() => {
    // Function to handle tab visibility change
    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        const cartLength = getCartItemsLength(document.cookie)
        if (cartLength !== cookies.cartItemsLength) {
          updateCartItemsCount(Number(cartLength))
          setCookie(cookiesKey.cartItemsLength, cartLength, { path: '/' })
        }
      }
    }

    handleVisibilityChange()

    // Add an event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [cookies.cartItemsLength, setCookie, cookieCartItemsLength])

  //check whether there is any popup of type chat and event timer, and trigger it automatically if any.
  const checkChatPopup = () => {
    const chatPopups = company.popups.filter(
      (popup) => popup.type === 'chat' && popup.event === 'timer',
    )
    setCompany({ ...company, popupsChecked: true })
    chatPopups.forEach((popup) => {
      if (!cookies['hide_fluid_popup_' + popup.id]) {
        modifyChatWithPopup(popup, false)
        setTimeout(function () {
          modifyChatWithPopup(popup, true)
        }, parseInt(popup.seconds) * 1000)

        setCookie('hide_fluid_popup_' + popup.id, true, {
          path: '/',
          maxAge: 15 * 86400,
        })
      }
    })
  }

  const modifyChatWithPopup = (popup: any, shouldChatOpen = false) => {
    if (popup && popup.type === 'chat') {
      let newChat: Partial<Company['chat']> = {}

      if (popup.title) {
        newChat.name = popup.title
      }
      if (popup.body) {
        // storing in tempDiv and changing into plainText since popup gives
        // html instead of text content
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = popup.body
        const plainTextBody = tempDiv.textContent || tempDiv.innerText || ''

        const chatPromptEl = document.getElementById('chat-greeting')
        if (chatPromptEl) {
          chatPromptEl.innerText = plainTextBody
        }
        newChat.message = plainTextBody
      }

      if (popup.button_text) {
        newChat.buttonText = popup.button_text
      }

      setOriginalChat((prevState) => ({
        ...prevState,
        modified: true,
        modifiedBy: popup.id,
        chat: company.chat,
      }))

      setCompany((prevCompany) => ({
        ...prevCompany,
        chatOpen: shouldChatOpen,
        popupsChecked: true,
        displaySuccessMessage: popup?.display_success_message,
        chat: {
          ...prevCompany.chat,
          ...newChat,
          collect: [...popup.collect],
        },
      }))
    }
  }

  const updateVisitOnProductPage = async (params: any, apiHost: string) => {
    const checkout_button = document.querySelectorAll(
      '[data-fluid-enrollment], [data-fluid-checkout]',
    )
    if (checkout_button?.length) {
      const { affiliate, visitor } = params
      await client.post(`${apiHost}custom_activities`, {
        custom_activity: {
          name: 'product-detail-page',
          visitor: visitor,
          affiliate: affiliate?.id,
          parameters: {},
        },
      })
    }
  }

  const addSpaceForBanner = (paddingTop: string) => {
    document.body.style.paddingTop = paddingTop
    const menuFixedTop = document.getElementsByClassName(
      'ui menu fixed',
    ) as HTMLCollectionOf<HTMLElement>

    if (menuFixedTop.length) {
      menuFixedTop[0].style.top = paddingTop
    }

    const smoothWrapper = document.getElementById(
      'smooth-wrapper',
    ) as HTMLDivElement | null

    if (smoothWrapper) {
      bannerStyle.innerHTML = `#smooth-wrapper{top: ${paddingTop} !important}`
      document.head.appendChild(bannerStyle)
    }
  }

  const confirmVisitor = async (visitorToken: string) => {
    try {
      const apiHostUrl =
        appSetting.api_url_host[appSetting.api_url_host.length - 1] === '/'
          ? appSetting.api_url_host
          : `${appSetting.api_url_host}/`
      await client.post(
        `${apiHostUrl}api/confirm-visitor`,
        {
          token: visitorToken,
        },
        { timeout: 30000 },
      )
    } catch (error) {
      if (isClientError(error)) {
        if (!error?.response) {
          confirmVisitor(visitorToken)
        }
      }
    }
  }

  useEffect(() => {
    if (
      !company.showBanner ||
      !!!company.banner ||
      company?.banner?.sticky_banner
    ) {
      return
    }
    const scrollPos = window.scrollY
    const fluidBanner = document.getElementById(
      'fluid-script-banner',
    ) as HTMLDivElement | null

    if (scrollPos <= bannerHeight) {
      addSpaceForBanner(bannerTop + 'px')
    } else {
      addSpaceForBanner('0px')
    }
    fluidBanner && (fluidBanner.style.top = `-${scrollPos}px`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerTop])
  /**
   * Will trigger once on app load.
   * send query based on window.fcs
   * and set initial states
   */

  useEffect(() => {
    if (cookies.has_fluid_banner) {
      addSpaceForBanner(bannerHeight + 'px')
      document.body.classList.add('has-fluid-banner')
    }

    const title =
      document.querySelector<HTMLTitleElement>('head title')?.innerText
    const desc = document
      .querySelector<HTMLMetaElement>('meta[name="description"]')
      ?.getAttribute('content')
    const ogTitle = document
      .querySelector<HTMLMetaElement>('meta[property="og:title"]')
      ?.getAttribute('content')
    const ogImage = document
      .querySelector<HTMLMetaElement>('meta[property="og:image"]')
      ?.getAttribute('content')
    const ogDescription = document
      .querySelector<HTMLMetaElement>('meta[property="og:description"]')
      ?.getAttribute('content')

    if (!appSetting) {
      return
    }

    appSetting.title = title ? title : ogTitle
    appSetting.image_url = ogImage
    appSetting.description = desc ? desc : ogDescription
    appSetting.url = window.location.href
    appSetting.cart = {
      enabled: false !== appSetting?.cart ? company.cart?.enabled : false,
    }
    appSetting.language_iso = cookies.locale || 'en'
    appSetting.share_token =
      new URLSearchParams(window.location.search)?.get('share_token') ?? null
    const apiHostUrl = appSetting.api_url_host

    if (cookies.fluid_v && !appSetting.visitor) {
      appSetting.visitor = cookies.fluid_v
    }
    const fetchData = async () => {
      try {
        const { response } = await sendChatRequest(appSetting)
        const result = response.data
        const companyProps: any = {
          isLoaded: true,
          ...result.company,
        }

        if (result.visitor) {
          setCookie(
            cookiesKey.fluid_v,
            result?.visitor,
            result?.visitor_expires_at
              ? {
                  expires: new Date(result.visitor_expires_at),
                  path: '/',
                  // domain: appSetting.api_url_host,
                }
              : {},
          )
        }

        companyProps.color =
          !companyProps.color || companyProps.color === '#'
            ? '#0c7cf6'
            : companyProps.color

        if (result.popups.length) {
          companyProps.popups = result.popups
        }

        if (cookies.hidePrompt) {
          companyProps.prompt = false
        }

        if (false !== appSetting.chat && result.chat) {
          companyProps.chat = result.chat

          if (props && props.message) {
            companyProps.chat.message = props.message
            companyProps.chatOpen = true
          }
        }
        if (result?.cart) {
          result?.cart?.cart_token &&
            setCookie(
              cookiesKey.cartToken,
              result?.cart?.cart_token,
              cookieOption,
            )
        }

        if (appSetting.cart) {
          companyProps.cart = result.cart
          // if (!!!result.cart.items_count || result.cart.items_count < 1) {
          //   removeCookie(cookiesKey.cartItemsLength, { path: '/' })
          //   removeCookie(cookiesKey.cartToken, { path: '/' })
          // }
        }

        if (result.fluid_logo) {
          companyProps.fluidLogo = result.fluid_logo
        }

        if (result.chat_close) {
          companyProps.chatCloseIcon = result.chat_close
        }

        if (result.chat_icon) {
          companyProps.chatIcon = result.chat_icon
        }

        if (result.visitor) {
          companyProps.visitor = result.visitor
          // setCookie('fld_v', result.visitor, { maxAge: 365 * 86400, path: '/' })
        }

        if (
          !cookies.hideBanner &&
          false !== appSetting.banner &&
          result.banner
        ) {
          companyProps.showBanner = true
          companyProps.banner = result.banner
          setCookie('banner_text', result.banner.body, cookieOption)
          setCookie('banner_text_color', result.banner.text_color, cookieOption)
          setCookie(
            'banner_button_text',
            result.banner.button_text,
            cookieOption,
          )
          setCookie('banner_button_url', result.banner.button_url, cookieOption)
          setCookie('banner_background', result.banner.color, cookieOption)
          setCookie('has_fluid_banner', true, cookieOption)
          document.body.classList.add('has-fluid-banner')
          if (result.banner.sticky_banner) {
            document.body.classList.add('fluid-banner-sticky')
          }
          addSpaceForBanner(bannerHeight + 'px')
        } else {
          document.body.classList.remove('has-fluid-banner')
          addSpaceForBanner('')
          removeCookie('banner_text')
          removeCookie('banner_text_color')
          removeCookie('banner_button_text')
          removeCookie('banner_button_url')
          removeCookie('banner_background')
          removeCookie('has_fluid_banner')
        }

        if (appSetting) {
          companyProps.affiliate = appSetting.affiliate || result.affiliate
          if (!companyProps.affiliate?.id && result.affiliate?.id) {
            companyProps.affiliate = result.affiliate
          }
          companyProps.currentUserToken = appSetting.currentUserToken
        }

        if (apiHostUrl) {
          companyProps.apiUrlHost = apiHostUrl
        }
        let params = new URLSearchParams(window.location.search)
        let cartId = params.get('cart_id')
        if (
          appSetting.cart?.enabled === true &&
          cartId &&
          params.get('show_checkout_popup')
        ) {
          companyProps.showCartCheckoutPopup = true
        }
        setCompany({
          ...company,
          ...companyProps,
          defaultCountry: result.country,
        })
        updateVisitOnProductPage(result, apiHostUrl)
        setTimeout(() => {
          confirmVisitor(result.visitor)
        }, 10000)
      } catch (error) {
        console.log('Error in useEffect:', error)
      }
    }

    fetchData()

    const onScroll = () => {
      setBannerTop(bannerHeight - window.scrollY)
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFluidChatPopupButtonClick = (e: CustomEvent) => {
    const popupId = e.detail.id
    const p = company.popups.find((popup) => {
      return popup.id === parseInt(popupId)
    })

    modifyChatWithPopup(p)
  }

  const onFluidEnrollmentButtonClick = (e: CustomEvent) => {
    const enrollment = e.detail.isEnrollment
    const quantity = e.detail.quantity
    const isSubscription = e.detail.isSubscription
    const isVariant = e.detail.type === 'variant'
    const enrollmentPackId = parseInt(e.detail?.enrollmentPackId)

    let ids = e.detail.ids
    ids = ids.replace(/\s/g, '')
    const orderIds = ids.split(',').map((id: string) => parseInt(id))
    openCheckoutPopup(
      isVariant ? [] : orderIds,
      isVariant ? orderIds[0] : undefined,
      enrollment,
      quantity,
      isSubscription,
      enrollmentPackId,
    )
  }

  /**
   * This triggered whenever there is change in company state
   */

  useEffect(() => {
    /**
     * Custom event `FluidPopupButtonClick` listener on window object
     */
    if (company.isLoaded && !popupEvent) {
      setPopupEvent(true)
      window.addEventListener(
        'FluidChatPopupButtonClick',
        onFluidChatPopupButtonClick,
      )
    }

    /**
     * Custom event `FluidEnrollmentButtonClick` listener on window object
     */
    if (company.isLoaded && !enrollmentEvent) {
      window.addEventListener(
        'FluidEnrollmentButtonClick',
        onFluidEnrollmentButtonClick,
      )
      setEnrollmentEvent(true) // Enrollment event registered
    }

    if (company.isLoaded && !company.popupsChecked) {
      checkChatPopup()
    }

    if (company.popups.length > 0 && !popups) {
      setPopups(true)
    }

    if (company.isLoaded && !popupEvent) {
      window.addEventListener('FluidChatButtonClick', onClickHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  /**
   * Show cart checkout pop up
   * usage window.fluidSettings.cart.showCheckoutPopup()
   */
  const openCheckoutPopup = (
    productIds: Array<number> = [],
    variantId: number | undefined = undefined,
    enrollment: boolean = false,
    quantity: number = 1,
    isSubscription: boolean = false,
    enrollmentPackId: number,
  ) => {
    setCompany((prevCompany) => {
      const cartObject =
        productIds.length > 0 || variantId
          ? {
              cart: {
                ...prevCompany.cart,
                productIds: productIds,
                variantId,
                cart_type: enrollment ? 'enrollment' : 'regular',
                quantity: quantity,
                subscription: isSubscription,
                enrollmentPackId,
              },
            }
          : {}

      return {
        ...prevCompany,
        ...cartObject,
        showCartCheckoutPopup: appSetting.cart?.enabled,
      }
    })
  }

  useEffect(() => {
    if (!appSetting) {
      return
    }
    appSetting.cart = {
      ...(appSetting.cart || {}),
      showCheckoutPopup: openCheckoutPopup,
      hideCheckoutPopup: () =>
        setCompany({ ...company, showCartCheckoutPopup: false }),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const onClickHandler = async () => {
    const updatedOriginalChat = {
      ...originalChat,
      modified: false,
      modifiedBy: undefined,
    }

    const updatedCompany = {
      ...company,
      chatOpen: !company.chatOpen,
      prompt: false,
    }

    if (originalChat.modified) {
      updatedCompany.chat = originalChat.chat
      appSetting.show_popup = false
      try {
        await sendChatRequest(appSetting)
        if (company?.messageDelivered) {
          updatedCompany.messageDelivered = false
          updatedCompany.formSubmitted = false
        }
      } catch (error) {
        console.log({ error })
      }
    }

    setOriginalChat(updatedOriginalChat)
    setCompany(updatedCompany)
    setCookie('hidePrompt', true, cookieOption)
  }

  const hidePrompt = () => {
    setCompany({ ...company, prompt: false })
    setCookie('hidePrompt', true, cookieOption)
  }

  const hideBanner = () => {
    const menuFixedTop = document.getElementsByClassName(
      'ui menu fixed',
    )[0] as HTMLElement | null

    if (menuFixedTop) {
      menuFixedTop.style.top = '0'
    }

    document.body.style.paddingTop = ''

    setCompany((prevCompany) => ({
      ...prevCompany,
      showBanner: false,
    }))
    addSpaceForBanner('')
    setCookie('hideBanner', true, cookieOption)
  }

  const closeModal = () => {
    setCompany({ ...company, chatOpen: false })
  }

  const handleSubmit = (formData: Company['message'], reset: () => void) => {
    setCompany((prevCompany) => ({
      ...prevCompany,
      formSubmitted: true,
      message: {
        ...prevCompany.message,
        ...formData,
        errors: {},
      },
    }))

    if (!company.messageDelivered) {
      const params: Partial<IData> = {
        affiliate: company.affiliate,
        visitor: company.visitor,
        contact: {
          full_name: formData.name,
          email: formData.email,
          phone: formData.phone,
        },
        message: formData.messageBody,
      }

      if (originalChat.modified && originalChat.modifiedBy) {
        params.popup_id = originalChat.modifiedBy
      }

      client
        .post(
          company.apiUrlHost +
            'api/company/chat/' +
            (originalChat.modified ? 'popup' : 'message') +
            '.json',
          params,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        )
        .then(function ({ data: { success, visitor_token, ...rest } }) {
          if (success) {
            if (originalChat.modified) {
              // need changes here
              setCookie('hidePrompt', true, cookieOption)
              setCompany((prevCompany) => ({
                ...prevCompany,
                messageDelivered: true,
                errorSendingMessage: false,
                visitor: visitor_token,
                autoResponse: {
                  ...rest,
                },
              }))
              reset()
              if (!company?.displaySuccessMessage) window.location.reload()
              return
            }

            setCompany((prevCompany) => ({
              ...prevCompany,
              messageDelivered: true,
              errorSendingMessage: false,
              visitor: visitor_token,

              autoResponse: {
                ...rest,
              },
            }))
          }
        })
        .catch(function ({ response }) {
          const { data } = response

          setTimeout(() => {
            setCompany((prevCompany) => ({
              ...prevCompany,
              formSubmitted: false,
              errorSendingMessage: true,
              message: {
                ...prevCompany.message,
                errors: {
                  ...prevCompany.message.errors,
                  ...data,
                },
              },
            }))
          }, 1000)
        })
    }
  }

  const cartItemPresent = useMemo(
    () => !!Number(company?.cart?.items_count),
    [company?.cart?.items_count],
  )

  return (
    <FluidChatWrapperStyled>
      <CompanyProvider company={company}>
        <LocaleProvider>
          <>
            {/* Show banner from cookie if possible to avoid pushing down page
        content due to banner*/}
            {(!cookies.hideBanner &&
              appSetting?.banner &&
              company.showBanner &&
              cookies.banner_text && (
                <Banner
                  color={cookies.banner_background}
                  textColor={cookies.banner_text_color}
                  body={cookies.banner_text}
                  buttonText={cookies.banner_button_text}
                  buttonUrl={cookies.banner_button_url}
                  hideBanner={hideBanner}
                ></Banner>
              )) ||
              (company.showBanner && company.banner && (
                <Banner
                  color={company.banner.color}
                  textColor={company.banner.text_color}
                  body={company.banner.body}
                  buttonText={company.banner.button_text}
                  buttonUrl={company.banner.button_url}
                  hideBanner={hideBanner}
                ></Banner>
              ))}
            {company.popups.map(function (popup, i) {
              return popup.type === 'modal' ? (
                <Popup
                  key={i}
                  image={popup.image}
                  id={popup.id}
                  title={popup.title}
                  collect={popup.collect}
                  event={popup.event}
                  body={popup.body}
                  buttonText={popup.button_text}
                  seconds={popup.seconds}
                  buttonRedirect={popup.button_redirect}
                  textColor={popup?.text_color}
                  backgroundColor={popup?.background_color}
                  buttonBackgroundColor={popup?.button_color}
                  buttonTextColor={popup?.button_text_color}
                  customClass={popup?.class_name}
                  displaySuccessMessage={popup?.display_success_message}
                ></Popup>
              ) : (
                ''
              )
            })}
            {!!company.chat && (
              <Chat
                closeModal={closeModal}
                hidePrompt={hidePrompt}
                handleSubmit={handleSubmit}
                onClickHandler={onClickHandler}
                isVisibleChatBubble={isVisibleChatBubble}
                setVisibleChatBubble={setVisibleChatBubble}
              />
            )}
            {company.cart?.enabled && cartItemPresent && (
              <FloatingCart
                openCheckoutPopup={openCheckoutPopup}
                isVisibleChatBubble={isVisibleChatBubble}
                setVisibleChatBubble={setVisibleChatBubble}
              />
            )}
            <Suspense fallback={'Loading....'}>
              {company.showCartCheckoutPopup && company.isLoaded && (
                <>
                  <div id="device-fingerprint" style={{ display: 'none' }} />
                  <StyledPopup
                    style={{
                      display: 'none',
                      justifyContent: 'center',
                      zIndex: 20000,
                      height: '300px',
                      width: '600px',
                      alignItems: 'center',
                      left: '50%',
                      top: '50%',
                      position: 'absolute',
                      transform: 'translate(-50%, -50%)',
                    }}
                    id="challenge-modal"
                  >
                    <div id="challenge"></div>
                  </StyledPopup>
                  <CartCheckout
                    apiUrlHost={appSetting.api_url_host}
                    setCompany={setCompany}
                  />
                </>
              )}
            </Suspense>
          </>
        </LocaleProvider>
      </CompanyProvider>
    </FluidChatWrapperStyled>
  )
}

export default AppElement
