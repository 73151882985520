import { useTransition } from 'react-spring'

import { useCompany } from '../../context/CompanyContext'
import {
  BubbleIcon,
  StyledAnimatedMessageButton,
  StyledCartCount,
  StyledContainer,
} from '../../styled'
// import { useCookies } from 'react-cookie'

const FloatingCart = (props: any) => {
  // const [cookies] = useCookies()
  const company = useCompany()
  const fadeInBubble = useTransition(props.isVisibleChatBubble, {
    from: { x: 30, opacity: 0 },
    enter: { x: 0, opacity: 1, delay: 400 },
    leave: { x: 30, opacity: 0 },
  })
  return (
    <StyledContainer
      className={
        company?.showCartCheckoutPopup ? 'fluid-checkout-popup-open' : ''
      }
      style={{ marginBottom: '70px' }}
    >
      {fadeInBubble((style) => {
        return (
          <StyledAnimatedMessageButton
            onClick={() => props.openCheckoutPopup()}
            style={style}
            color={company?.color}
            id="cart-widget"
          >
            <div>
              <BubbleIcon
                src={
                  company?.cart?.icon ||
                  'https://ik.imagekit.io/fluid/s3/cart_icon.svg'
                }
                alt="Open Cart"
              />
              <StyledCartCount>{company?.cart?.items_count}</StyledCartCount>
            </div>
          </StyledAnimatedMessageButton>
        )
      })}
    </StyledContainer>
  )
}

export default FloatingCart
