import { useState, useEffect } from 'react'

declare global {
  interface Window {
    cookieStore: {
      get: (name: string) => Promise<{ value: string } | undefined>
      addEventListener: (type: string, listener: () => void) => void
      removeEventListener: (type: string, listener: () => void) => void
    }
  }
}

const useCookieWatcher = (cookieName: string, interval = 300) => {
  const [cookieValue, setCookieValue] = useState(() => {
    const cookies = document.cookie.split(';')
    const cookie = cookies.find((c) => c.trim().startsWith(`${cookieName}=`))
    return cookie ? cookie.split('=')[1] : null
  })

  useEffect(() => {
    let intervalId = 0

    const getCookie = (name: string) => {
      const cookies = document.cookie.split(';')
      const cookie = cookies.find((c) => c.trim().startsWith(`${name}=`))
      return cookie ? cookie.split('=')[1] : null
    }

    // If modern CookieStore API is available
    if (typeof (window as any).cookieStore !== 'undefined') {
      const handleChange = async () => {
        const newValue = await window.cookieStore.get(cookieName)
        setCookieValue(newValue?.value ?? null)
      }

      window.cookieStore.addEventListener('change', handleChange)

      return () => {
        window.cookieStore.removeEventListener('change', handleChange)
      }
    } else {
      let lastValue = getCookie(cookieName)

      intervalId = setInterval(() => {
        const currentValue = getCookie(cookieName)
        if (currentValue !== lastValue) {
          lastValue = currentValue
          setCookieValue(currentValue)
        }
      }, interval)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [cookieName, interval])

  return cookieValue
}

export default useCookieWatcher
