/* eslint-disable no-restricted-globals */
import axios from 'axios'

const client = axios.create({
  baseURL: window.fcs.api_url_host,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${window.fcs?.currentUserToken}`,
  },
})

const getFingerPrint = async () => {
  const fingerPrintData = {
    userAgent: navigator.userAgent,
    language: navigator.language,
    colorDepth: screen.colorDepth || '',
    screenResolution: `${screen.width}x${screen.height}`,
    timezoneOffset: new Date().getTimezoneOffset(),
    platform: navigator.platform,
    hardwareConcurrency: navigator.hardwareConcurrency || null,
    deviceMemory: (navigator as any).deviceMemory || null,
  }

  const fingerPrintString = JSON.stringify(fingerPrintData)
  const fingerPrintHash = btoa(fingerPrintString)

  return fingerPrintHash
}

getFingerPrint().then((visitorId) => {
  client.interceptors.request.use((config) => {
    config.headers = config.headers ?? {}
    if (
      visitorId &&
      typeof visitorId === 'string' &&
      window.fcs.enableFingerPrinting
    )
      config.headers['X-VISITOR-ID'] = visitorId || ''
    return config
  })
})

const isClientError = axios.isAxiosError

export default client
export { isClientError }
